@import './mixin.scss';
@import "./var.scss";
@import "./fonts.scss";

.buscador {
    box-shadow: 0 0 5px 0 rgba(#000, .2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.categoriaSingular {
    position: relative;
    border: 1px solid #F47921;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        @include toAnim(0.3s);
    }

    h3 {
        font-size: 1.125rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: rgba(#57585A, .5);
        color: #FFF;
        padding: 1rem;
        margin-bottom: 0;
        z-index: 2;
    }

    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
            background: -moz-linear-gradient(180deg, rgba(255, 255, 255, .0) 50%, rgba(244, 121, 33, .9) 100%);
            background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, .0) 50%, rgba(244, 121, 33, .9) 100%);
            background: linear-gradient(180deg, rgba(255, 255, 255, .0) 50%, rgba(244, 121, 33, .9) 100%);
        }

        h3 {
            background-color: transparent;
        }
    }
}

.articuloSingular {
    background-color: #FFF;
    box-shadow: 0px 0px 10px 0px #00000033;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    &:hover {
        box-shadow: 0px 0px 10px 0px $naranja;

        .Link_seguir {
            font-weight: bold;
            position: relative;
            margin-right: 1vw;
            transition: all 0.2s;
        }
    }

    .linkT {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
}


.badge {
    background-color: #F47921;
    color: #FFF;
    padding: 5px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.entradaSingular {
    border: 1px solid #F47921;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: relative;
    cursor: pointer;

    &:hover {

        .Link_seguir {
            font-weight: bold;
            position: relative;
            margin-left: 1vw;
            transition: all 0.2s;
        }
    }

    .linkT {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    h3 {
        color: #F47921;
    }



}

.Link_seguir {
    color: $naranja;
    font-size: 0.833vw;
    text-decoration: none;

    span {
        border-bottom: 1px solid $naranja;
    }
}


.link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.wpcontrol {
    width: 12vw;
}

.text_informativo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.texts {
    width: 60%;
}

.fzlista {
    font-size: 0.833vw;
}

.h_imgInformativa {
    height: 3vw;
}

.hpControl {
    min-height: 7vw;
    width: 15vw;
}

.controlBtn_blogCatego {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.imgsize {
    background-size: cover;
}

.containerImg_noticias {
    img {
        width: 100%;
        height: 100%;
    }
}

//big desktop
@media (min-width: 1920px) {}

@media only screen and (min-width: 1201px) and (max-width: 1920px) {
    .controlBtn_blogCatego {
        //justify-content: space-between;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .hpControl {
        min-height: 7rem;
        width: 15rem;
    }

    .Link_seguir {
        font-size: 1rem;
    }

    .wpcontrol {
        width: 12rem;
    }

    .texts {
        width: 70%;
    }

    .fzlista {
        font-size: 0.833rem;
    }

    .h_imgInformativa {
        height: 3rem;
    }

    .contenido_Tarjetas {
        position: relative;
        padding: 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .hpControl {
        min-height: 7rem;
        width: 14rem;
    }

    .fzlista {
        font-size: 0.833rem;
    }

    .h_imgInformativa {
        height: 3rem;
    }

    .Link_seguir {
        font-size: 1rem;
    }

    .wpcontrol {
        width: 10rem;
    }
}

//only tablet
@media (max-width: 767px) {

    .contenido_Tarjetas {
        position: relative;
        padding: 1rem;
    }

    .Link_seguir {
        font-size: 0.9rem;
    }

    .wpcontrol {
        width: 11rem;
    }

    .texts {
        width: 90%;
    }

    .fzlista {
        font-size: 0.833rem;
    }

    .h_imgInformativa {
        height: 3rem;
    }

    .hpControl {
        min-height: 7rem;
        width: 10rem;
    }
}

//only phone
@media (max-width: 576px) {
    .Link_seguir {
        font-size: 0.9rem;
    }

    .wpcontrol {
        width: 10rem;
    }

    .texts {
        width: 100%;
    }


}