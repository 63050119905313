@import './mixin.scss';
@import './var.scss';

.iteminformativo {
    border: 1px solid $naranja;
    display: inline-flex;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    padding: 0vw 1.5vw 0.2vw 0.5vw;
    flex-direction: column;
}

.imgenIcon {
    width: 1.2vw;
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iteminformativo {

        padding: 0.5rem 1.5rem 0.2rem 0.5rem;
    }

    .imgenIcon {
        width: 1.5rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .iteminformativo {

        padding: 0.5rem 1.5rem 0.2rem 0.5rem;
    }

    .imgenIcon {
        width: 1.5rem;
    }
}

@media (max-width: 768px) {
    .iteminformativo {
        padding: 0.5rem 1.5rem 0.2rem 0.5rem;
    }

    .imgenIcon {
        width: 1.5rem;
    }
}

//only phone
@media (max-width: 576px) {
    .iteminformativo {
        padding: 0.5rem 1.5rem 0.2rem 0.5rem;
    }

    .imgenIcon {
        width: 1.5rem;
    }
}