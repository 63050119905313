@import "./var.scss";
@import "./mixin.scss";

.btngeneral {
    position: relative;
}

.btnHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.btnOrdenar {
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid $gris;
    color: $gris;

    span {
        font-size: 0.9vw;
    }
}

.desplegable {
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 15px 0px #00000040;
    padding: 1.2vw 1vw;
    top: 3vw;
    right: 0;
    z-index: 99;
}

.btndesplegable {
    border: 0;
    background-color: transparent;
}

.textbtn {
    white-space: nowrap;
}



.containerBtn {
    border: 1px solid #78787880;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 0.5vw 0.8vw;

    .btn {
        border: 0;
        outline: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
    }

    span {
        font-size: 0.5vw;
        color: $gris_6;
    }

    .vhIconFilter {
        height: 1vw;
    }

}

//big desktop
@media (min-width: 1920px) {}


//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

@media (max-width: 1200px) {
    .desplegable {
        padding: 1.2rem 1rem;
        top: 3rem;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .btnOrdenar {
        span {
            font-size: 0.9rem;
        }
    }

    .containerBtn {
        padding: 0.5rem 1rem;

        span {
            font-size: 0.7rem;
        }

        .vhIconFilter {
            height: 1rem;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .iconOrdenar {
        min-width: 1rem;
    }

    .btnOrdenar {
        span {
            font-size: 1rem;
        }
    }

    .containerBtn {
        padding: 0.5rem 1rem;

        span {
            font-size: 0.7rem;
        }
        .vhIconFilter {
            height: 1rem;
        }
    }
}

@media (max-width: 767px) {
    .iconOrdenar {
        min-width: 1rem;
    }

    .btnOrdenar {
        span {
            font-size: 0.9rem;
        }
    }

    .containerBtn {
        padding: 0.5rem 1rem;

        span {
            font-size: 0.7rem;
        }
        .vhIconFilter {
            height: 1rem;
        }
    }
}

//only phone
@media (max-width: 576px) {

    .btnOrdenar {
        span {
            font-size: 0.9rem;
        }
    }

    .iconOrdenar {
        min-width: 1rem;
    }

    .desplegable {
        padding: 1.2rem 1rem;
        top: 3rem;
        right: 0;

    }

}