@import './var.scss';
@import './mixin.scss';

.card_pagos {
    border: 1px solid $gris-claro;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 1.5vw 0vw;
    min-width: 60%;
}

.informacion1 {

    border-bottom: 1px solid $gris-claro;
}

.iconpagos {
    line-height: 100%;
    filter: grayscale(100%);
    width: 0.8vw;
}

.textos_informativos {
    display: flex;
    justify-content: center;
    line-height: 1.1;

    .parrafo {
        width: 70%;
    }
}
.btnspagos{
  width: 40%;
}

@media (min-width: 1920px) {
    .btnspagos{
        max-width: 40%;
      }
}
//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iconpagos {
        width: 0.9rem;
    }

    .textos_informativos {
        .parrafo {
            width: 90%;
        }
    }
    .btnspagos{
        width: 50%;
      }
}


//only phone
@media (max-width: 767px) {
    .iconpagos {
        width: 0.6rem;
    }
}

//only phone
@media (max-width: 576px) {
    .iconpagos {
        width: 1rem;
    }
    .textos_informativos {
        .parrafo {
            width: 100%;
        }
    }
    .btnspagos{
        width: 80%;
      }
}