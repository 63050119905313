@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

.card,
.certificado {
    background-color: white;
    box-shadow: 0px 0px 10px 0px #59545633;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    position: relative;
    padding: 0.6vw;
}


@media (max-width: 992px) {
    .card {
        max-width: 90%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        @include toAnim(0.3s);
        background-color: white;
        opacity: 0;
        visibility: hidden;
        padding: 1.5rem;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        box-shadow: 0 1px 5px rgb(0, 0, 0, 0.25);
    }

    .showCard {
        opacity: 1;
        visibility: visible;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .certificado {
        padding: 0.6rem;
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .card {
        padding: 0.6rem;
    }
    .certificado {
        padding: 0.6rem;
    }
}

@media (max-width: 767px) {
    .certificado {
        padding: 0.6rem;
    }
}
//only phone
@media (max-width: 576px) {
    .card {
        max-width: 100%;
        top: 50%;
        left: 20%;
        transform: translate(-12%, -50%);
    }


    .certificado {
        padding: 0.6rem;
    }
}