@import './mixin.scss';
@import './var.scss';

.labels {
    position: relative;
    padding-bottom: 0.5vw;
    color: $gris;
    font-size: 1.250vw;
}

.select_proyecto {
    option::after {
        font-size: 0.833vw;
    }
}