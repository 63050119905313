@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

.collapse_container.active {
    background-color: $naranja;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.card_informacion {
    box-shadow: 0px 0px 10px 0px #57585A33;
    padding: 1.8vw;

  
}


.headercollapse {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: $gris-claro;
    padding: 0.5vw 1vw;
    cursor: pointer;
    color: $gris;
    &:hover{
        background-color: $naranja;
        color: white;
        @include toAnim(0.3s);
        .icon_collapsephone {
            color: white; 
        }
    }
}

.headercollapse.active {
    background-color: $naranja;
}

.icon_collapsephone {
    color: $naranja;
    font-size: 1.5vw;
   
}

.icon_collapseflecha {
    font-size: 1.3vw; 
  
}

.icon_collapsephone.active {
    color: #fff;
}



.icon_collapseflecha.active {
    color: #fff;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .icon_collapsephone {
        font-size: 1.5rem;
    }

    .icon_collapseflecha {
        font-size: 1.3rem;
    }

    .card_informacion {
        padding: 1rem;
    }

    .headercollapse {
        padding: 0.5rem 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .icon_collapsephone {
        font-size: 1.5rem;
    }
    .icon_collapseflecha {
        font-size: 1.3rem;
    }
    .card_informacion {
        padding: 1rem;
    }
    .headercollapse {
        padding: 0.5rem 1rem;
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .icon_collapsephone {
        font-size: 1.5rem;
    }

    .icon_collapseflecha {
        font-size: 1.3rem;
    }

    .card_informacion {
        padding: 1rem;
    }

    .headercollapse {
        padding: 0.5rem 1rem;
    }
}

//only phone
@media (max-width: 576px) {
    .icon_collapsephone {
        font-size: 1.5rem;
    }

    .icon_collapseflecha {
        font-size: 1.3rem;
    }

    .card_informacion {
        padding: 1rem;
    }

    .headercollapse {
        padding: 0.5rem 1rem;
    }
}