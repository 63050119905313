@import "./var.scss";
@import "./mixin.scss";

.wSearch {
	width: 16vw;
	display: flex;
}

.wListResult {
	width: 33vw;
	cursor: pointer;
}

.borderSearch {
	border: 1px solid $gris;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.1vw;
	transition: all 0.3s;

	&:focus {
		padding: 0;
	}
}

.swd {
	box-shadow: 0px -1px 10px 0px rgba(230, 231, 233, 1);
	-webkit-box-shadow: 0px -1px 10px 0px rgba(230, 231, 233, 1);
	-moz-box-shadow: 0px -1px 10px 0px rgba(230, 231, 233, 1);
}

.wBox {
	width: 100%;
}

.sizeIcon {
	width: 3vw;
}

.wTxt {
	width: 50%;
}

.mxh_imgSolicitud {
	max-height: 3.3vw;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.mxh_imgSolicitud {
		max-height: 2.8rem;
	}

	.wListResult {
		width: 30rem;
	}

	.wSearch {
		width: auto;
	}

	.wBox {
		width: 90%;
	}

	.sizeIcon {
		width: 4rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

	.mxh_imgSolicitud {
		max-height: 2.8rem;
	}
	.wListResult {
		width: 40rem;
	}

	.wSearch {
		width: auto;
	}

	.wBox {
		width: 90%;
	}

	.sizeIcon {
		width: 4rem;
	}
}

@media (max-width: 768px) {

	.wListResult {
		width: 100%;
	}

	.wSearch {
		width: auto;
	}

	.sizeIcon {
		width: 3rem;
	}
	.mxh_imgSolicitud {
		max-height: 2.8rem;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.wBox {
		width: 90%;
	}

	.sizeIcon {
		width: 3rem;
	}
}

//only phone
@media (max-width: 576px) {
	.wBox {
		width: 90%;
	}

	.sizeIcon {
		width: 4rem;
	}

	.wTxt {
		width: 90%;
	}
}