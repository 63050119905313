@import "./var.scss";
@import "./fonts.scss";

.custom_select {
    position: relative;
    //display: inline-block;
    cursor: pointer;
    border: 1px solid #D9D9D9;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 0.2vw 0.9vw;
    background-color: #fff;
}

.select_header {

    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    span {
        font-size: 0.69vw;
        white-space: nowrap;
    }
}

.icon {
    font-size: 1.5vw;
    display: inline-flex;
   
}

.wp_auto{
    width: auto !important;
}

.select-header:hover {
    background-color: white;
}

.options {
    display: block;
    /* Inicialmente oculto */
    position: relative;
    z-index: 1;
    background-color: #fff;

    //max-height: 100%;
    //overflow-y: auto;
    span {
        font-size: 0.9vw;
    }
}

.custom_option {
    padding: 0.5vw;
    transition: background-color 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 16vw;
}

.custom_option:hover {
    background-color: white;
}

.custom_option.selected {
    background-color: white;
    color: #fff;
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .custom_select {
        min-width: 16rem;
        padding: 0.9rem;
        width: 100%;

    }

    .options {
        span {
            font-size: 0.9rem;
        }
    }

    .select_header {
        span {
            font-size: 0.9rem;
        }
    }

    .icon {
        font-size: 1.5rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .custom_select {
        min-width: 16rem;
        padding: 0.9rem;
        width: 100%;
    }

    .options {
        span {
            font-size: 0.9rem;
        }
    }

    .select_header {
        span {
            font-size: 0.9rem;
        }
    }

    .icon {
        font-size: 1.5rem;
    }
}

//only sm 
@media (max-width: 767px) {
    .custom_select {
        min-width: 16rem;
        padding: 0.9rem;

    }

    .options {
        span {
            font-size: 0.9rem;
        }
    }

    .select_header {
        span {
            font-size: 0.9rem;
        }
    }

    .icon {
        font-size: 1.5rem;
    }
}

//only sm 
@media (max-width: 576px) {
    .custom_select {
        width: 100%;

    }
}