@import "./var.scss";
@import "./fonts.scss";


.infopagar {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding:  0.5vw 0.7vw 0.7vw 1.5vw;
}

.resumen_pago , .infopagar {
    background-color: $gris-claro; 
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.resumen_pago{
    display: flex;
    align-items:center ;
    padding:  0.9vw 2vw 0.9vw 3vw;
}

.numeroencargo{
 display: flex;
 align-self: self-end;
}

.boton_pagar{
    padding-left: 5vw;
    padding-right: 5vw;
}

.wpIcongcodigo{
    img{
        height: 1.5vw;
    }
}
//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .infopagar {
        padding:  0.5rem 0.7rem 0.7rem 1rem;
       
    }

    .boton_pagar{
        position: relative;
        margin-top: 1rem;
    }
    .wpIcongcodigo{
        img{
            height: 1.5rem;
        }
    }

    .resumen_pago{
        padding:  0.9rem 2rem 0.9rem 3rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .infopagar {
        padding:  0.5rem 0.7rem 0.7rem 1rem;
    }
    .boton_pagar{
        position: relative;
        margin-top: 1rem;
    }
    .boton_pagar{
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .wpIcongcodigo{
        img{
            height: 1.5rem;
        }
    }
}

@media (max-width: 767px) {
    .infopagar {
        padding:  0.5rem ;
    }
    .boton_pagar{
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .wpIcongcodigo{
        img{
            height: 1.5rem;
        }
    }

    .resumen_pago{
        padding:  0.9rem 2rem 0.9rem 3rem;
    }
}


//only phone
@media (max-width: 576px) {
    .infopagar {
        padding:  0.5rem ;
    }
    .boton_pagar{
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .wpIcongcodigo{
        img{
            height: 1.5rem;
        }
    }

    .resumen_pago{
        padding:  0.9rem 1rem 0.9rem 1.5rem;
    }
}