@import './var.scss';
@import './mixin.scss';

.ubicationProperty{
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.hoverUbi{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    text-decoration: none;
    padding: 1vw;
    @include toAnim(0.3s);
    background: rgb(217,217,217);
    background: linear-gradient(180deg, rgba(217,217,217,0) 0%, rgba(244,121,33,1) 100%);
}

.ubicationProperty:hover .hoverUbi{
    opacity: 1;
}