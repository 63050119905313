@import "./var.scss";
@import "./fonts.scss";

.tarjeta {
    background-color: $gris-claro;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 0.8vw;
}

.tarjeta_body {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
        line-height: 1.2;
    }
}

.tarjeta_boton {
    display: flex;
    justify-content: flex-end;

    button {
        border: 0;
        background-color: transparent;
        font-size: 0.7vw;
        color: $naranja;
    }
}

.icon {
    height: 1.8vw;
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .icon {
        height: 1.8rem;
    }

    .tarjeta_boton {
        button {
            font-size: 0.8rem;
        }
    }
    .tarjeta {
        padding: 0.8rem;
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .tarjeta_boton {
        button {
            font-size: 0.7rem;
        }
    }
}
@media (max-width: 767px) {

    .icon {
        height: 1.8rem;
    }

    .tarjeta_boton {
        button {
            font-size: 0.7rem;
        }
    }

    .tarjeta {
        padding: 0.8rem;
    }
}
@media (max-width: 576px) {

    .icon {
        height: 1.8rem;
    }

    .tarjeta_boton {
        button {
            font-size: 0.7rem;
        }
    }

    .tarjeta {
        padding: 0.8rem;
    }
}