

@import "./var.scss";
@import "./mixin.scss";
.cuadro {
	padding: 0.3vw 2.5vw;
	background-color: $gris-claro;
	width: 90%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	.iconcuadro {
		color: $naranja;
		font-size: 2.7vw;
	}
}

@media (max-width: 1200px) {
	
	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}
}

@media (max-width: 768px) {

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}

}

//only phone
@media (max-width: 576px) {

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}

}