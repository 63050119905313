@import './var.scss';

.singularCardJunta {
    min-height: 14rem;
    box-shadow: 0 0 10px 0 rgba(#000, .2);
    border-left: 2px solid $naranja;
    border-radius: 2px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.estructuraOrganizacionalSingular {
    box-shadow: 0 0 10px 0 rgba(#BA3709, 0.25);

    h3 {
        font-weight: bold;
        margin-bottom: 1.5rem;
    }
}

.estructuraOrganizacionalGerenciaGeneral {
    h3 {
        color: $naranja !important;
    }
}

.estructuraOrganizacionalCadenaValor {
    h3 {
        color: $ladrillo-oscuro !important;
    }
}

.estructuraOrganizacionalUnidadSoporte {
    h3 {
        color: $ocre !important;
    }
}

.sectionResponsabilidadSocial {
    background-color: $gris;
    color: #FFF;
}


.slideResponsabilidad:nth-child(odd) {
    margin-top: 3vw;
}



.itemImgResponsabilidadSocial {
    position: relative;
    border: 1px solid $naranja;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.itemImgContentResponsabilidadSocial {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 95%;
    margin: 0 auto;
    background-color: rgba($gris, 0.7);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;


    .parrafo_detalle {
        display: none;
    }

    &:hover {
        .parrafo_detalle {
            display: block;
        }

    }
}

.cursor_link {
    cursor: pointer;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.items_card {
    width: 15%;
}


.table_resultado {
    box-shadow: 0px 0px 10px 0px #00000040;
    padding: 1.3vw;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}


//big desktop
@media (min-width: 1920px) {}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

@media (max-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .items_card {
        width: 25%;
    }

    .table_resultado {
        padding: 1.3rem;
    }

    .imgMapa {
        img {
            height: 30rem;
            width: auto !important;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

@media (max-width: 767px) {
    .items_card {
        width: 30%;
    }

    .slideResponsabilidad:nth-child(odd) {
        margin-top: 2rem;
    }

    .table_resultado {
        padding: 1.3rem;
    }
}

//only phone
@media (max-width: 576px) {
    .items_card {
        width: 24%;
    }

    .slideResponsabilidad:nth-child(odd) {
        margin-top: 0rem;
    }

    .table_resultado {
        padding: 1.3rem;
    }
}