@import './mixin.scss';
@import "./var.scss";
@import "./fonts.scss";

.logradoTagSingular {
    background-color: #FFF;
    box-shadow: 0 0 10px 0 rgba(#F47921, .25);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

}

.numeroTagSingular {
    color: #F47921;
    font-size: 1.250rem;
}

.pilarSingular {
    h3 {
        font-weight: normal;
    }
}

.circlePilar {
    margin-left: auto;
    margin-right: auto;
    width: 8rem;
    height: 8rem;
    background-color: $naranja;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.certificacionSingular {
    background-color: #FFF;
    box-shadow: 0 0 10px 0 rgba(#000, .2);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.certificacionLogo {
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    background-color: #FFF;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
}

.input_btn {
    display: none;
}

.input_btn:checked+.certificacionLogo {
    border: 1px solid #F47921;

}

.caracteristicaSingularImg {
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 50px;
    background-color: #E6E7E9;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.theProject {
    border: 1px solid #F47921;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 96%;

    a {
        color: #F47921;
    }
}

.theProjectImg {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.textosIcon {
    white-space: nowrap;
}

.Link_seguir {
    color: $naranja;
    font-size: 0.833vw;

    span {
        border-bottom: 1px solid $naranja;
    }
}

.cardseccion {
    position: relative;
    background-image: url('../../public/img/fondosos.jpg');
    background-size: cover;
}



.ContainerClificador {
    cursor: pointer;

    .contenido {
        // visibility: hidden;
        //opacity: 0;
        // padding: 0.3vw 0vw;
    }

    &:hover {
        .contenido {
            // visibility: visible;
            //opacity: 1;
            // transition: all 0.5s;
            //background-color: $gris-claro;
            // padding: 0.3vw 0vw;

        }
    }
}

.iconQMide {
    max-height: 2.2vw;
}

.imgLogocertificaciones {
    height: 3.4vw !important;
}

//big desktop
@media (min-width: 1920px) {
    .textosIcon {
        white-space: wrap;
    }
    .imgLogocertificaciones {
        height: 3.4rem !important;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .imgLogocertificaciones {
        height: 3.4rem !important;
    }

    .iconQMide {
        max-height: 2.2rem;
    }

    .textosIcon {
        white-space: wrap;
    }

    .Link_seguir {
        font-size: 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .imgLogocertificaciones {
        height: 3.4rem !important;
    }

    .iconQMide {
        max-height: 2.2rem;
    }

    .textosIcon {
        white-space: wrap;
    }

    .Link_seguir {
        font-size: 1rem;
    }
}

//only tablet
@media (max-width: 767px) {
    .Link_seguir {
        font-size: 0.9rem;
    }

    .iconQMide {
        max-height: 2.2rem;
    }
    .imgLogocertificaciones {
        height: 3.4rem !important;
    }
}

//only phone
@media (max-width: 576px) {
    .textosIcon {
        white-space: wrap;
    }

    .Link_seguir {
        font-size: 0.9rem;
    }
}