@import "./var.scss";
@import "./mixin.scss";

.tarjeta_informacion {
    display: flex;
    background-color: $gris-claro;
    align-items: center;
    position: relative;
    padding: 0.5vw 1vw;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

}

.ultimo_element {
    align-self: flex-end;
}

.fechas {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.iconImg {
    max-width: 4vw;
}

.deshabilitada {
    opacity: 0.5;
    pointer-events: none;

    .iconImg {
        filter: grayscale(100%);
    }
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .iconImg {
        max-width: 4rem;
    }

    .tarjeta_informacion {
        padding: 0.5rem 1rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

//only sm 
@media (max-width: 767px) {
    .iconImg {
        max-width: 3.7rem;
    }

    .tarjeta_informacion {
        padding: 0.5rem 1rem;
    }
}

//only phone
@media (max-width: 576px) {
    .iconImg {
        max-width: 4rem;
        position: relative;
        padding-bottom: 1rem;
    }

    .tarjeta_informacion {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ultimo_element {
        align-self: flex-start;
    }

    .element_info {
        align-self: flex-start;
    }
}