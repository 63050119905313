@import './var.scss';
@import './mixin.scss';

.bgS {
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 72%;
        background-color: $gris;
        position: absolute;
        left: 0;
        top: 15%;
        z-index: 0;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
}

.videoContent {
    position: relative;
    display: flex;
    align-items: center;
}

.wpPhone {
    width: 20vw;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 1.8vw;
    -moz-border-radius: 1.8vw;
    border-radius: 1.8vw;
    margin-bottom: 5vw;
    transform: translateX(30%);
}

.dummyPhone {
    margin-top: 5vw;
}

.casePhone {
    width: 100%;
    position: relative;
    z-index: 1;
}

.player {
    width: 98%;
    height: 98%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
        video {
            width: auto !important;
            height: 100%;
        }
    }
}

.control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
}


// big desktop
@media only screen and (min-width: 1920px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {}

// big tablet LG
@media (max-width: 1200px) {
    .wpPhone {
       margin-bottom: 5rem;
   }

   .dummyPhone {
       margin-top: 5rem;
   }
   .bgS {
   
       &::before {
           height: 65%;
       }
   }
}


@media (max-width: 1200px) and (orientation: landscape) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .wpPhone {
        position: relative;
        margin-bottom: 0rem;
    }

    .dummyPhone {
        margin-top: 0rem;
    }
    .bgS {
    
        &::before {
            height: 70%;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
    .wpPhone {
         width: 40vw;
        margin-bottom: 5rem;
    }

    .dummyPhone {
        margin-top: 5rem;
    }
    .bgS {
    
        &::before {
            height: 65%;
        }
    }

}

// tablet MD
@media (max-width: 767px) {
    .videoContent {
        width: 100%;
        justify-content: center;
        margin-bottom: 2rem;
    }

    .dummyPhone {
        margin-top: 2rem;
    }
    .bgS {
    
        &::before {
            height: 90%;
        }
    }

    .wpPhone {
        width: 40vw;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        margin-top: 5rem;
    }
}

// only mobile

@media (max-width: 576px) {

    .wpPhone {
        width: 40vw;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    .bgS {
        &::before {
            width: 98%;
            left: 1%;
            top: 10%;
            height: 92%;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }
    }

}