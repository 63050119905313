.inversionistaLoginPage {
    height: 81vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    background-color: rgba(#FFF, 0.8);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.contenedorFormPerfil {
    box-shadow: 0 0 10px 0 rgba(#57585A, .25);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.formPerfil {
    width: 75%;
    margin: 0 auto;
}