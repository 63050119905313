@import "./btn.module.scss";

.imgHeight {
	height: 29vw;
}

.Container_imgHeight {
	.imgHeight {
		height: 37vw;
	}
}

.banner {
	height: 30vh;
}

.video {
	border-radius: 20px;
}

.recuadro {
	position: relative;

	&::before {
		content: "";
		width: 75%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		background: linear-gradient(180deg, #bd3b17, #fb7706);
		border-radius: 20px;
		z-index: 0;
	}
}

.experiencia {
	position: relative;
	background: linear-gradient(250deg, #bd3b17, #fb7706);
	border-radius: 20px;
	padding: 1.5rem 5rem;

	.pagrafo {
		width: 80%;
	}
}

.listado {
	width: 90%;

	li {
		font-weight: 400;
		text-align: justify;
		list-style: none;
		//margin-right: 6rem;
	}
}

.Icon {
	width: auto;

	.iconSize {
		font-size: 2vw;
	}
}


.itemList {
	width: 90%;
}

.sw_cards {
	//width: 53.594vw;
	width: 65%;
	//height: 32.448vw;
	position: relative;
}

.sw_cards_slider {
	//position: relative;
	border-radius: 18px;
	font-size: 22px;
	font-weight: bold;
	color: #fff;
	/* object-fit: cover;
	width: 100%; */

	/* &:first-child {
		transform: translate3d(calc(0px + -7.25%), 0px, -100px) rotateZ(0deg) scale(1) !important;
	}

	&:last-child {
		transform: translate3d(calc(-200% + 7.25%), 0px, -100px) rotateZ(0deg) scale(1) !important;
	} */
}

.image_container {
	height: 100%;
	position: relative;
}

.img_container {
	position: relative;
	display: inline-block;
}

.text_block {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 10px;
	box-sizing: border-box;

	p,
	h1,
	h2,
	h3 {
		margin: 0;
		flex-wrap: wrap;
	}
}

.list_starContainer {
	display: flex;
	flex-wrap: wrap;

}

.list_star {
	flex: 0 0 auto;
	width: 18%;
	position: relative;
	margin-right: 2.5vw
}

.borde_gris {
	border-bottom: 1px solid $gris-claro;
}

.sw_container {
	box-shadow: 0px 0px 15px 0px #00000040;
	border-radius: 10px;
	padding: 0.5rem;
}

.btn_white {
	display: inline-block;
	border: 1px solid $gris-claro;
	border-radius: 0.5vw;
	cursor: pointer;
	position: relative;
	margin-right: 0.6vw;
	font-size: 1.4vw;
	padding: 0.5vw 2.5vw;
	color: $naranja;
	background: #f9f9f9;

	&:hover {
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		background: #fff;
	}
}

.fw-500 {
	font-weight: 500;
}

.fs_big {
	font-size: 3vw;
}

.bg_map {
	background-image: url("../../public/img/nosotros/projects.png");
	background-color: $gris;
	background-size: 100% auto;
	background-position: bottom;
	background-repeat: no-repeat;
}

.bg_BannerOferta {
	//background-image: url("../../public/img/galeryfondo.jpg");
	background-color: $gris;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	height: 400px;
	//display: flex;
}

.contenido {
	flex: 1;
	background-color: rgba(230, 231, 233, 0.95);
	padding: 1vw;
	height: 100%;
	width: 55%;
}

.premios {
	position: relative;
	--swiper-navigation-size: 1.5vw;
	--swiper-navigation-color: $gris;
	--swiper-navigation-sides-offset: 1px;

	.zindex_1 {
		z-index: 1;
	}
}

.jumboLogo {
	width: 13.7vw;
}

.list_premios {
	border-top: 10px none;
	border-bottom: 1px double $gris;
	border-left: 1px double $gris;
	border-bottom-left-radius: 4px;
	// border: 1px solid $gris;

	h3 {
		font-size: 0.8vw;
	}

	p {
		font-size: 0.7vw;
	}
}

.list_grid {
	--swiper-navigation-size: 1.5vw;
	--swiper-navigation-color: $gris;
	--swiper-navigation-sides-offset: 1px;
}

.borderCircle {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border: 1px solid $gris-claro;
	border-radius: 50%;
	width: 6vw;
	height: 6vw;
	overflow: hidden;
	cursor: pointer;

	.img_size {
		width: 3vw;
		object-fit: none;
	}

	&:hover {
		border-color: $naranja;
	}

	&.clicked {
		border-color: $naranja;
	}
}

.reco {
	margin: 0 auto;
	box-shadow: 0 3px 5px rgb(0, 0, 0, 0.25);
}

.w_logo {
	width: 22vw;
	margin-top: 5rem;
}

/* navigation */
.arrow {
	position: absolute;
	bottom: 50%;
	width: 100%;
	padding: 0 10px;

	img {
		width: 1.5vw;
	}

	margin-top: -5px;
	top: 50%;

	.arrow-left {
		left: 0;
	}

	.arrow-right {
		right: 0;
	}
}

.mainWrap {
	--swiper-navigation-size: 1.5vw;
	--swiper-navigation-color: #fff;

	.swiper-button-prev {
		background: #f47f2b;
		border-radius: 50%;
		padding: 1.3vw;

		&::after {
			background: #f47f2b;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: 45%;
		width: 40px;
		height: 40px;
		background: $naranja;
		border: 1px solid $naranja;
		border-radius: 50%;
		color: #fff;
		outline: 0;
		transition: background-color 0.2s ease, color 0.2s ease;

		&::after {
			font-size: 16px;
		}
	}
}

.history {
	/* width: 100%; */
	//height: 330px;
	/* Center slide text vertically */
	/* display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-bottom: 1rem; */
	width: 32%;
	/* img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	} */

	/* &:nth-child(2n) {
		width: 49%;
	}

	&:nth-child(3n) {
		width: 49%;
	}

	&:nth-child(4n) {
		width: 27%;
	}

	&:nth-child(5n) {
		width: 49%;
	} */
}

.double {
	width: 57%;
}

.decoTimeline {
	width: 26px;
	height: 100%;
	position: relative;

	&::after {
		position: absolute;
		content: "";
		top: 24px;
		left: 50%;
		transform: translateX(-50%);
		width: 3px;
		height: calc(100% - 24px);
		background: rgba(189, 59, 23, 1);
		background: -moz-linear-gradient(top,
				rgba(189, 59, 23, 1) 0%,
				rgba(189, 59, 23, 1) 0%,
				rgba(251, 120, 6, 1) 100%);
		background: -webkit-gradient(left top,
				left bottom,
				color-stop(0%, rgba(189, 59, 23, 1)),
				color-stop(0%, rgba(189, 59, 23, 1)),
				color-stop(100%, rgba(251, 120, 6, 1)));
		background: -webkit-linear-gradient(top,
				rgba(189, 59, 23, 1) 0%,
				rgba(189, 59, 23, 1) 0%,
				rgba(251, 120, 6, 1) 100%);
		background: -o-linear-gradient(top,
				rgba(189, 59, 23, 1) 0%,
				rgba(189, 59, 23, 1) 0%,
				rgba(251, 120, 6, 1) 100%);
		background: -ms-linear-gradient(top,
				rgba(189, 59, 23, 1) 0%,
				rgba(189, 59, 23, 1) 0%,
				rgba(251, 120, 6, 1) 100%);
		background: linear-gradient(to bottom,
				rgba(189, 59, 23, 1) 0%,
				rgba(189, 59, 23, 1) 0%,
				rgba(251, 120, 6, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bd3b17', endColorstr='#fb7806', GradientType=0);
	}

	&::before {
		width: 24px;
		height: 24px;
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
		background: rgba(251, 120, 6, 1);
		background: -moz-linear-gradient(top,
				rgba(251, 120, 6, 1) 0%,
				rgba(251, 120, 6, 1) 0%,
				rgba(189, 59, 23, 1) 100%);
		background: -webkit-gradient(left top,
				left bottom,
				color-stop(0%, rgba(251, 120, 6, 1)),
				color-stop(0%, rgba(251, 120, 6, 1)),
				color-stop(100%, rgba(189, 59, 23, 1)));
		background: -webkit-linear-gradient(top,
				rgba(251, 120, 6, 1) 0%,
				rgba(251, 120, 6, 1) 0%,
				rgba(189, 59, 23, 1) 100%);
		background: -o-linear-gradient(top,
				rgba(251, 120, 6, 1) 0%,
				rgba(251, 120, 6, 1) 0%,
				rgba(189, 59, 23, 1) 100%);
		background: -ms-linear-gradient(top,
				rgba(251, 120, 6, 1) 0%,
				rgba(251, 120, 6, 1) 0%,
				rgba(189, 59, 23, 1) 100%);
		background: linear-gradient(to bottom,
				rgba(251, 120, 6, 1) 0%,
				rgba(251, 120, 6, 1) 0%,
				rgba(189, 59, 23, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb7806', endColorstr='#bd3b17', GradientType=0);
	}
}

.fatherHistory {
	width: calc(100% - 3px);
	display: flex;
	align-items: flex-start;
}

.size_img {
	width: 15vw;
}

.beneficiosImg {
	width: 95%;
}

.styleItems {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
}

.wpDesplegable {
	width: 50%;
}


//big desktop
@media (min-width: 1920px) {
	.imgHeight {
		height: 29vw;
	}

	.banner {
		height: 25vh;
	}

	.bg_BannerOferta {
		height: 650px;
		//display: flex;
	}
}

@media (max-width: 1200px) {
	.bg_BannerOferta {
		background-size: cover;
	}
}

//
@media (min-width: 769px) and (max-width: 992px) {
	.beneficiosImg {
		width: 65%;
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.wpDesplegable {
		width: 100%;
	}

	.contenido {
		padding: 1rem;
		width: 59%;
	}

	.list_star {
		width: 28%;
	}

	.imgHeight {
		height: 18rem;
	}

	.banner {
		height: 12rem;
	}

	.sw_cards {
		width: 35rem;
		height: 23rem;
	}

	.list_grid {
		--swiper-navigation-size: 2rem;
	}

	.list_premios {
		h3 {
			font-size: 1rem;
		}

		p {
			font-size: 0.8rem;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.Icon {
		width: 2rem;

		.iconSize {
			font-size: 1.5rem;
		}
	}

	.recuadro {
		width: calc(100% - 2rem);
		margin: 0 1rem;

		&::before {
			width: 100%;
		}
	}

	.borderCircle {
		width: 6rem;
		height: 6rem;

		.img_size {
			width: 4rem;
			padding: .3rem;
		}
	}

	.premios {
		--swiper-navigation-size: 1.5rem;
	}

	.Container_imgHeight {
		.imgHeight {
			height: 37rem;
		}
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

	.contenido {
		padding: 1rem;
		width: 57%;
	}

	.list_star {
		width: 25%;
	}

	.wpDesplegable {
		width: 75%;
	}

	.imgHeight {
		height: 20rem;
	}

	.banner {
		height: 12rem;
	}

	.heightImg {
		height: 300px;

		img {
			height: auto;
		}
	}

	.arrow {
		img {
			width: 1.5rem;
		}
	}

	.list_premios {
		h3 {
			font-size: 1rem;
		}

		p {
			font-size: 0.8rem;
		}
	}

	.Icon {
		width: 2rem;

		.iconSize {
			font-size: 1.5rem;
		}
	}

	.recuadro {
		width: calc(100% - 2rem);
		margin: 0 1rem;

		&::before {
			width: 100%;
		}
	}

	.borderCircle {
		width: 6rem;
		height: 6rem;

		.img_size {
			width: 4rem;
			padding: .3rem;
		}
	}

	.premios {
		--swiper-navigation-size: 1.5rem;
	}

	.Container_imgHeight {
		.imgHeight {
			height: 32rem;
		}
	}
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
	.Container_imgHeight {
		.imgHeight {
			height: 32rem;
		}
	}

	.list_star {
		width: 40%;
		margin-bottom: 1rem;
	}

	/* .image_container {
		height: 100%;
	}
	.wpDesplegable {
		width: 70%;
	}

	/* .sw_cards_slider {
		height: auto;
	} */

	.text_block {
		position: relative;
		height: 100%;
	}
}

@media (max-width: 768px) {
	.fatherHistory {
		display: block;
	}

	.arrow {
		img {
			width: 1.5rem;
		}
	}

	.list_premios {

		h3 {
			font-size: .8rem;
		}

		p {
			font-size: 0.6rem;
		}
	}

	.list_grid {
		--swiper-navigation-size: 2.5rem;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.Icon {
		width: 2rem;

		.iconSize {
			font-size: 1.5rem;
		}
	}

	.beneficiosImg {
		width: 65%;
	}

	.recuadro {
		width: calc(100% - 2rem);
		margin: 0 1rem;

		&::before {
			width: 100%;
		}
	}

	.borderCircle {
		width: 6rem;
		height: 6rem;

		.img_size {
			width: 4rem;
			padding: .3rem;
		}
	}

	.premios {
		--swiper-navigation-size: 1.5rem;
	}
}

//only phone
@media (max-width: 576px) {

	.contenido {
		background-color: rgba(230, 231, 233, 0.75);
		/* Color con transparencia */
		padding: 20px;
		width: 100%;
	}

	.wpDesplegable {
		width: 100%;
	}

	.styleItems {
		flex-wrap: wrap;
	}

	.image_container {
		height: 100%;
	}

	/* .sw_cards_slider {
		height: auto;
	} */

	.text_block {
		position: relative;
		height: 100%;
	}

	.list_premios {
		margin-right: 2.3rem;

		h3 {
			font-size: .8rem;
		}

		p {
			font-size: 0.6rem;
		}
	}

	.list_grid {
		--swiper-navigation-size: 2.5rem;
		padding-left: 2.3rem;
	}

	.imgHeight {
		height: 14rem;
	}

	.banner {
		height: 20rem;
	}

	.listado {
		width: 90%;
	}

	.Icon {
		width: 2rem;

		.iconSize {
			font-size: 1.5rem;
		}
	}

	.itemList {
		width: 100%;
	}



	.list_star {
		width: 40%;
		position: relative;
		margin-bottom: 1rem;
		margin-right: 1rem;
	}

	.sw_cards {
		width: 100%;
		height: auto;
	}

	.experiencia {
		padding: 1.5rem;

		.pagrafo {
			width: 100%;
		}
	}

	.father_history {
		display: block;
	}

	.jumboLogo {
		width: 12rem;
		margin: 2rem auto;
	}

	.arrow {
		img {
			width: 1.5rem;
		}
	}

	.Container_imgHeight {
		.imgHeight {
			height: 32rem;
		}
	}
}

@media (max-width: 280px) {
	.borderCircle {
		width: 7rem;
		height: 7rem;

		.img_size {
			width: 4.3rem;
			padding: .3rem;
		}
	}

	.premios {
		--swiper-navigation-size: 1.5rem;
	}
}