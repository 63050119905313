@import "./var.scss";
@import "./mixin.scss";

.blend {
	mix-blend-mode: multiply;
	background-image: url("../../public/img/comunicate/deep.png");
	background-color: #dedfe1;
	background-size: 100% auto;
	background-position: bottom;
	background-repeat: no-repeat;
	padding: 1vw;
}

.radius20 {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.orangeIcon {
	width: 4.9vw;
	height: 4.9vw;
	background-color: $naranja;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.2vw;
}

.circleIcon {
	max-width: initial; /* Ajusta el tamaño del icono dentro del círculo */
	height: auto;
}

.gridColumn2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0.5vw;
	grid-row-gap: 0;
}

.pl {
	padding-right: 1vw;
	padding-left: 1vw;
}

.lh28 {
	line-height: 28px;
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {
}

//big desktop
@media (min-width: 1920px) {
}

//desktop
@media (min-width: 1200px) {
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.orangeIcon {
		width: 3.2rem;
		height: 3.2rem;
		padding: 1rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.orangeIcon {
		width: 3.2rem;
		height: 3.2rem;
		padding: 1rem;
	}
}

@media (max-width: 767px) {
	.gridColumn2 {
		grid-template-columns: repeat(1, 1fr);
	}

	.orangeIcon {
		width: 3.2rem;
		height: 3.2rem;
		padding: 1rem;
	}
}

//only phone
@media (max-width: 576px) {
	.orangeIcon {
		width: 3.3rem;
		height: 3.3rem;
		padding: 0.7rem;
	}
}
