@import './mixin.scss';
@import './var.scss';


.selectores {
    display: flex;
    justify-items: center;
}

.wpSelect {
    width: 50%;
}

.lista {
    position: relative;
    margin: 0;
    padding-left: 0.5vw;

    .li_item {
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 0.833vw;
    }

    .li_item::before {
        content: "\2022";
        color: $naranja;
        margin-right: 1vw;
        font-size: 3vw;
    }
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .lista {
    
        .li_item {
            font-size: 1rem;
        }
    
        .li_item::before {
            content: "\2022";
            font-size: 2.5rem;
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

//only phone
@media (max-width: 576px) {

    .selectores {
        display: block;
        justify-items: center;
    }

    .wpSelect {
        width: 100%;
        position: relative;
        margin-top: 0.5rem;
    }

    .lista {
    
        .li_item {
            font-size: 0.833rem;
        }
    
        .li_item::before {
            content: "\2022";
            font-size: 2.5rem;
        }
    }
}