@import "./var.scss";
@import "./fonts.scss";

.descripcion_tramite {
    border-left: 1px solid $gris-claro;
    padding-left: 3vw;
}

.lista_pasos {
    list-style: none;
    padding: 0;
}

.icono {
    width: 8%;
    color: $naranja;
    font-size: 1.8vw;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw 0;
    svg{
        height: 1.8vw;
    }
}

.complete{
    .icono{
        visibility: visible;
        position: relative;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: black;
            z-index: 0;
        }
        svg, img{
            position: relative;
            z-index: 1;
        }
    }
    .img_lista{
        filter: grayscale(0%);
    }

 
}

.last{
    //background-color: black;
    .icono{
        &::before{
            height: 50%;
            top: 0;
            transform: translate(-50%, 0%);
        }
    }
}

.item_lista {
    //margin-bottom: 0.521vw;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    cursor: pointer;
    &:nth-child(1){
        .icono{
            &::before{
                top: 50%;
                height: 50%;
                transform: translate(-50%, 30%);
            }
        }
    }

    &:hover{
        p, span{
            color: $naranja;
        }
    }
}

.img_lista {
    width: 12%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0vw 2.5vw; */
    filter: grayscale(100%);
    img{
        height: 2vw;
    }
}

.txtWp{
    width: 80%;
    display: flex;
    align-items: center;
}

.texto {
    color: $gris;
}
.active{
    .texto{
        color: $naranja;
    }
}

.fechas{
    display: flex;
    align-items: center;
}
//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .icono {
        font-size: 1.8rem;
    }

    .texto {
        font-size: 1.2rem;
    }
    .img_lista {
        img{
            height: 2rem;
        }
    }

    .item_lista {

        &:nth-child(1){
            .icono{
                &::before{
                    top: 40%;
                }
            }
        }
    
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .fechas{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

//only phone
@media (max-width: 767px) {
    .icono {
        font-size: 1.5rem;
    }
    .img_lista {
        img{
            height: 2rem;
        }
    }

}
//only phone
@media (max-width: 576px) {
    .icono {
        font-size: 1.5rem;
    }

    .img_lista {
        img{
            height: 1.8rem;
        }
    }
}