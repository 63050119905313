@import "./var.scss";
@import "./mixin.scss";

//first desktop


.fondo {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 75%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(251, 120, 6, 1);
        background: -moz-linear-gradient(left, rgba(251, 120, 6, 1) 0%, rgba(251, 120, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(251, 120, 6, 1)), color-stop(0%, rgba(251, 120, 6, 1)), color-stop(100%, rgba(189, 59, 23, 1)));
        background: -webkit-linear-gradient(left, rgba(251, 120, 6, 1) 0%, rgba(251, 120, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
        background: -o-linear-gradient(left, rgba(251, 120, 6, 1) 0%, rgba(251, 120, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
        background: -ms-linear-gradient(left, rgba(251, 120, 6, 1) 0%, rgba(251, 120, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
        background: linear-gradient(to right, rgba(251, 120, 6, 1) 0%, rgba(251, 120, 6, 1) 0%, rgba(189, 59, 23, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb7806', endColorstr='#bd3b17', GradientType=1);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }

    h2,
    p {
        color: white;
    }
}

.pf {
    p {
        font-size: 1.2vw !important;
    }
}


.wpcontrolv2 {
    width: 17vw;

}

.TituloBanner {
    margin-bottom: 16vw;
    text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35);
}

//big desktop
@media (min-width: 1920px) {}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

@media (max-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .fondo {
        &::before {
            //display: none;
            height: 100%;
        }

    }

    .pf {
        p {
            font-size: 1rem !important;
        }
    }

    .wpcontrolv2 {
        width: 10rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .pf {
        p {
            font-size: 1rem !important;
        }
    }

    .wpcontrolv2 {
        width: 13rem;
    }
    .TituloBanner {
        margin-bottom: 5rem;
    }
}

@media (max-width: 992px) {
    .fondo {
        &::before {
            //display: none;
            height: 100%;
        }
    }
}

@media (max-width: 768px) {
    .fondo {
        &::before {
            //display: none;
            height: 100%;
        }

        h2,
        p {
            // color: $gris;
        }
    }

    .pf {
        p {
            font-size: 1rem !important;
        }
    }
}

@media (max-width: 767px) {
    .wpcontrolv2 {
        width: 22%;
    }
}

//only phone
@media (max-width: 576px) {
    .TituloBanner {
        margin-bottom: 8rem;
    }
}