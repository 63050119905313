@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

.gestion_card {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #00000026;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4vw;
    position: relative;
    width: 25%;
    color: $gris;


    &:hover {
        background-color: $naranja;
        color: white;
        @include toAnim(0.3s);

        .iconImg {

            filter: brightness(0) invert(1);

        }
    }
}


.iconImg {
    img {
        width: 100% !important;
    }

}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

    .iconImg {
        img {
            width: 2vw;
        }

    }

    .gestion_card {
        padding: 2.4rem;
        width: 40%;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .gestion_card {
        padding: 2.4rem;
        width: 30%;
    }


    .iconImg {
        img {
            width: 2vw;
        }

    }
}

//tablet
@media (max-width: 767px) {

    .iconImg {
        img {
            width: 2vw;
        }

    }

    .gestion_card {
        padding: 2.4rem;
        width: 30%;
    }
}

//only phone
@media (max-width: 576px) {

    .iconImg {
        img {
            width: 2vw;
        }

    }

    .gestion_card {
        padding: 2.4rem;
        width: 45%;
    }
}