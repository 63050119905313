@import './mixin.scss';
@import './var.scss';

.misinmuebles {
    box-shadow: 0px 0px 10px 0px #00000033;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    margin: 7px;
    position: relative;
}

.linea_vertical {
    border-left: 1px solid $gris;
    height: 2vw;
}

.titleinmueble {
    position: relative;
}

.link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.iconir {
    font-size: 2vw;
}



.altoImg {
    height: 8vw;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .linea_vertical {
        height: 3rem;
    }

    .iconir {
        font-size: 2rem;
    }

    .altoImg {
        height: 8rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

    .altoImg {
        height: 8rem;
    }
}
@media (min-width: 767px) {
  
}

@media (max-width: 767px) {
    .iconir {
        font-size: 2rem;
    }
    .altoImg {
        height: 8rem;
    }
}

@media (max-width: 576px) {
    .linea_vertical {
        height: 3rem;
    }

    .misinmuebles {
        flex-direction: column;
        align-items: center;
    }
}