@import "./var.scss";
@import "./mixin.scss";

.wpAccordion {
	display: flex;
	background-color: white;
}

.wpItem {
	max-width: 54vw;
	height: 100%;
	display: flex;
	align-items: stretch;
	border-left: 1px solid $gris-claro;
	background-color: white;
	cursor: pointer;
	@include toAnim(0.3s);
}

.activeItem{
	border-left: 1px solid $naranja;
}

.wpSVG {
	width: 6vw;

	rect {
		fill: white;
	}
}

.BtnItem {
	width: 6vw;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
	border-bottom: 1px solid $gris-claro;
	position: relative;
	overflow: hidden;
	@include toAnim(0.3s);
}

.activeBtn {
	width: 0;
}

.titleItem,
.titleItemVertical {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	h6 {
		white-space: nowrap;
		text-transform: capitalize;
	}

	img {
		height: 2vw;
	}
}

.titleItem {
	padding: 1vw 0;
}

.titleItemVertical {
	position: absolute;
	bottom: 2vw;
	left: 0;
	transform: translate(42%, -86%);
	rotate: 270deg;

	img {
		rotate: -270deg;
	}
}

.ContentItem {
	width: 55vw;
	padding: 0 1vw;
	background-color: white;
	@include toAnim(0.3s);
}

// estilos de collapse de interna  inmueles 
.botoncontrol {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid $gris-claro;
	cursor: pointer;
}

.iconcollapse {
	color: $naranja ;
	font-size: 2vw;
}

.controlesBtn {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.img {
	position: relative;
	overflow: hidden;
	height: 25vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imgItem {
	//max-height: 25vw;
	object-fit: cover;
}

.txtItem {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba($gris, 0.7);
	padding: 1vw;
}


//only desktop
@media (min-width: 1921px) {
	.ContentItem {
		width: 55rem;
	}
}

//only desktop
@media (min-width: 1200px) {
	
}


@media (min-width: 992px) and (max-width: 1200px) {

	.titleItemVertical {
		transform: translate(42%, -183%);
	}

}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.iconcollapse {
		font-size: 2rem;
	}

	.wpAccordion {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		background-color: white;
	}

	.wpItem {
		max-width: 100%;
		width: 100%;
		height: auto;
		display: block;
		border-left: 1px solid $gris-claro;
		background-color: white;
		cursor: pointer;
		@include toAnim(0.3s);
	}

	.activeItem {
		border-width: 4px solid $gris-claro;
	}

	.wpSVG {
		width: 6vw;
		display: none;

		rect {
			fill: white;
		}
	}

	.BtnItem {
		width: 100%;
		height: auto;
		position: relative;
		top: inherit;
		left: inherit;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 1rem;
		border-left: 0px solid $gris-claro;
		border-top: 1px solid $gris-claro;
		position: relative;
		overflow: hidden;
		@include toAnim(0.3s);
	}

	.titleItemVertical {
		position: relative;
		bottom: inherit;
		left: inherit;
		transform: translate(0, 0);
		rotate: 0deg;

		img {
			rotate: 0deg;
			height: 2rem;
		}
	}

	.ContentItem {
		width: 100%;
		padding: 0.5rem;
	}

	.img {
		height: auto;
		display: block;
	}

	.txtItem {
		position: relative;
		bottom: inherit;
		left: inherit;
		padding: 0.5rem;
	}

	.titleItem {
		display: none;

		img {
			height: 2rem;
		}
	}

	.imgItem {
		width: 100% !important;
		max-width: 100%;
		height: auto;
		max-height: none;
		object-fit: fill;
	}

}

@media (min-width: 576px) and (max-width: 768px) {
	.iconcollapse {
		font-size: 2rem;
	}
	

}

//only phone
@media (max-width: 576px) {

	
	.iconcollapse {
		font-size: 2rem;
	}
	
	.controlesBtn {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	.wpAccordion {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		background-color: white;
	}

	.wpItem {
		max-width: 100%;
		width: 100%;
		height: auto;
		display: block;
		border-left: 1px solid $gris-claro;
		background-color: white;
		cursor: pointer;
		@include toAnim(0.3s);
	}

	.activeItem {
		border-width: 4px solid $gris-claro;
	}

	.wpSVG {
		width: 6vw;
		display: none;

		rect {
			fill: white;
		}
	}

	.BtnItem {
		width: 100%;
		height: auto;
		position: relative;
		top: inherit;
		left: inherit;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 1rem;
		border-left: 0px solid $gris-claro;
		border-top: 1px solid $gris-claro;
		position: relative;
		overflow: hidden;
		@include toAnim(0.3s);
	}

	.titleItemVertical {
		position: relative;
		bottom: inherit;
		left: inherit;
		transform: translate(0, 0);
		rotate: 0deg;

		img {
			rotate: 0deg;
			height: 2rem;
		}
	}

	.ContentItem {
		width: 100%;
		padding: 0.5rem;
	}

	.img {
		height: auto;
		display: block;
	}

	.txtItem {
		position: relative;
		bottom: inherit;
		left: inherit;
		padding: 0.5rem;
	}

	.titleItem {
		display: none;

		img {
			height: 2rem;
		}
	}

	.imgItem {
		width: 100% !important;
		max-width: 100%;
		height: auto;
		max-height: none;
		object-fit: fill;
	}

}