@import './var.scss';
@import './mixin.scss';

.table_container {
    width: 100%;
}

.tabla {
    display: table;

    width: 100%;
}

.fila {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 100%;

}

.celda {
    flex: 1;
    padding: 0.521vw;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.span_cel_header {
    font-size: 1.250vw;
}

.span_celda {
    font-size: 0.833vw;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .span_cel_header {
        font-size: 1.250rem;
    }

    .span_celda {
        font-size: 0.833rem;
    }

    .celda {
        padding: 0.521rem;
        max-width: 15rem;
        min-width: 12rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .span_cel_header {
        font-size: 1.250rem;
    }

    .span_celda {
        font-size: 0.833rem;
    }

    .celda {
        padding: 0.521rem;
    }
}


@media (max-width: 767px) {
    .span_cel_header {
        font-size: 1.250rem;
    }

    .span_celda {
        font-size: 0.833rem;
    }

    .celda {
        padding: 0.5rem;
        flex: 0;
        max-width: 15rem;
        min-width: 12rem;
    }
}

//only phone
@media (max-width: 576px) {
    .span_cel_header {
        font-size: 1.250rem;
    }

    .span_celda {
        font-size: 0.833rem;
    }

    .fila {
        padding: 0.5rem;
    }

    .celda {
        padding: 0.5rem;
        flex: 0;
        max-width: 15rem;
        min-width: 12rem;
    }
}